import React from "react";
import ReactMarkdown from "react-markdown";

import styled from "styled-components";
import { css } from "styled-components";
import Link from "next/link";


const Text = styled.p`
    font-weight: 300;
    font-family: "Inter",-apple-system,sans-serif;
    font-size: 18px;
    letter-spacing: -.009em;
    cursor: pointer;
`;

const renderers = {
    paragraph: Text,
}

export default function SinglePostPreview({post}) {
    return (
        <section className="single-post-preview">
          {hasImage(post) ? PreviewWithImage({post}) : PreviewWithoutImage({post})}
          <style jsx>{`
           .single-post-preview {
               padding-bottom: 5%;
               margin-bottom: 2.5%;
               border-bottom: 1.75px solid #DDEAF2!important;
           }
           .row {
                display: flex;
                direction: row;
            }
            .article-text {
                order: 2;
            }
            img {
                order: 1;
            }
        `}</style>
        </section>
    );
}


function PreviewWithImage({ post }) {
    var path = "/".concat(post.fields.slug);
    return (
        <React.Fragment>
        <div className="article-text">
          <Link href={path}><h1>{post.fields.title}</h1></Link>
            <a href={path}><ReactMarkdown source={post.fields.excerpt} renderers={renderers} /></a>
        </div>
        <img src={post.fields.file.url} />
	    <style jsx>{`
                h1 {
                  font-family: "Calibre";
                  font-weight: 400;
                  cursor: pointer;
                }
                a:link {
                  color: #333;
                  text-decoration: none;
                }
                a:visited {
                  color: #333;
                  text-decoration: none;
                }
                a:active {
                  color: #333;
                  text-decoration: none;
                }
                a:hover {
                  color: #333;
                  text-decoration: none;
                }


            `}
	</style>
        </React.Fragment>
    );
}



function PreviewWithoutImage({ post }) {
    var path = "/".concat(post.fields.slug);
    return (

	<div className="article-text">
	        <Link href={path} as={path}><h1>{post.fields.title}</h1></Link>
	        <a href={path} as={path}><ReactMarkdown source={post.fields.excerpt} renderers={renderers} /></a>
	    <style jsx>{`
                h1 {
                  font-family: "Calibre";
                  cursor: pointer;
                }
                a:link {
                  color: #333;
                  text-decoration: none;
                }
                a:visited {
                  color: #333;
                  text-decoration: none;
                }
                a:active {
                  color: #333;
                  text-decoration: none;
                }
                a:hover {
                  color: #333;
                  text-decoration: none;
                }

            `}</style>
	    </div>
    );
}

function hasImage(post) {
    var hasImage = false;
    if ("file" in post.fields) {
        if ("url" in post.fields.file) {
            hasImage = true;
        }
    }
    return hasImage;
}
