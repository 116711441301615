import Link from 'next/link';

import Layout from "../components/layout";
import MultiplePostPreview from "../components/multiple_post_preview";
import { ContentfulService } from "../lib/contentful";


export default function Index({ posts }) {
    return (
        <Layout title={'Rockstar Finance'}>
          <MultiplePostPreview posts={posts} />
        </Layout>
    );
}


export async function getStaticProps() {
    const contentfulService = new ContentfulService();
    const preview_posts = await contentfulService.fetchPreviewPosts();
    return {props: {posts: preview_posts}};
}
