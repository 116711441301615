import { ContentfulService } from "../lib/contentful";
import React from "react";

import SinglePostPreview from "../components/single_post_preview";


export default function MultiplePostPreview({ posts }) {
    return (
	<div className="multi-preview-container">
	    {posts.map(
		post => (
		    <SinglePostPreview key={post.fields.slug} post={post} />
		)
	    )}
	<style jsx>{`
            .multi-preview-container {
                width: 100%;
                padding-right: 2.5%;
            }
        `}</style>
	</div>
    );
}
